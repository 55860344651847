import styles from "./Squarespace.module.scss";

import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { graphql, useStaticQuery } from "gatsby";

import i18n from "./Squarespace.i18n.json";
import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";
import EmbededCode from "../../embeded-code/EmbededCode";

import Integration from "../page/Integration";

function IntegrationSquarespace({ strings, locale }) {
  const data = useStaticQuery(graphql`
    query {
      illustrationCustomCSS: file(
        relativePath: { eq: "illustration/Custom CSS_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationSquarespace: file(
        relativePath: { eq: "illustration/Squarespace_instructions.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationIntegration: file(
        relativePath: { eq: "illustration/integration.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
  return (
    <Integration name="Squarespace">
      <div className={styles["container"]}>
        <div className={styles["titles"]}>
          <div className={styles["icon"]}>
            <GatsbyImage image={getImage(data.illustrationIntegration)} />
          </div>
          <h1>{strings["title"]}</h1>
          <h3>{strings["subtitle"]}</h3>
        </div>
        <div className={styles["steps"]}>
          <div
            className={styles["step"]}
            style={{ backgroundColor: "#fdce57" }}
          >
            <div className={styles["number"]}>1</div>
            <div className={styles["step_content"]}>
              <EmbededCode />
            </div>
          </div>
          <div
            className={styles["step"]}
            style={{ backgroundColor: "#fee1a0" }}
          >
            <div className={styles["number"]}>2</div>
            <div className={styles["step_content"]}>
              <div>
                <div>{strings["admin"]}</div>
                <ul>
                  <li>{strings["page"]}</li>
                  <li>{strings["create"]}</li>
                  <li>{strings["html"]}</li>
                  <li>{strings["paste"]}</li>
                </ul>
              </div>
              <div className={styles["illustration"]}>
                <GatsbyImage image={getImage(data.illustrationSquarespace)} />
              </div>
            </div>
          </div>
          <div
            className={styles["step"]}
            style={{ backgroundColor: "#feefd1" }}
          >
            <div className={styles["number"]}>3</div>
            <div className={styles["step_content"]}>
              <div>{strings["save"]}</div>
              <div>{strings["contact"]}</div>
            </div>
          </div>
        </div>
        <div className={styles["footer"]}>{strings["footer"]}</div>
      </div>
    </Integration>
  );
}

IntegrationSquarespace.propTypes = {
  locale: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(IntegrationSquarespace);
